import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';

import { BuyerAuctionDetailsModalComponent } from './buyer-auction-details-modal.component';
import { AuctionDetailViewModule } from '@cosBuyer/partials/auction-detail-view/auction-detail-view.module';

@NgModule({
  imports: [CommonModule, BaseModule, AuctionDetailViewModule],
  declarations: [BuyerAuctionDetailsModalComponent],
  exports: [BuyerAuctionDetailsModalComponent],
})
export class BuyerAuctionDetailsModalModule {}
