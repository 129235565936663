import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IBuyerAuctionBidView } from '@caronsale/cos-models';
import { GeneralPipesModule } from '@caronsale/frontend-pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

@Component({
  selector: 'app-bidders-list',
  templateUrl: './bidders-list.component.html',
  styleUrls: ['./bidders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, GeneralPipesModule, NgxSkeletonLoaderModule, DatePipe, EnzoComponentsModule],
})
export class BiddersListComponent {
  public bids = input.required<IBuyerAuctionBidView[][]>();
  public loading = input<boolean>(false);
}
