<div class="dialog-header">
  <enzo-headline level="3">{{ 'self-registration.buyer.headline' | translate }}</enzo-headline>

  <enzo-text kind="label">{{ 'self-registration.buyer.subtitle' | translate }}</enzo-text>
</div>

<mat-dialog-content>
  <section>
    <div class="text-and-icon-wrapper">
      <enzo-text kind="body-bold" balance="false" class="txt-related-to-icon">{{ 'self-registration.buyer.vat-section-title' | translate }}</enzo-text>
      <!-- tooltip icon - to include in V.O2 -->
      <!-- <enzo-icon icon="info-outline" color="purple" size="medium"></enzo-icon> -->
    </div>

    <enzo-textfield [formControl]="vatId" [label]="'account.buyer.vat-id' | translate" [placeholder]="'signup.type-here' | translate"> </enzo-textfield>
  </section>

  <div class="text-and-icon-wrapper" *ngIf="hasUploadedBusinessRegistrationDocument() === false">
    <enzo-text kind="body-bold" balance="false" class="txt-related-to-icon">{{ 'account.buyer.business-registration' | translate }}</enzo-text>
    <!-- tooltip icon - to include in V.O2 -->
    <!-- <enzo-icon icon="info-outline" color="purple" size="medium"></enzo-icon> -->
  </div>

  <enzo-file-upload
    *ngIf="hasUploadedBusinessRegistrationDocument() === false"
    [label]="'signup.upload-registration-documents' | translate"
    [description]="'self-registration.buyer.allowed-formats' | translate: { allowedFormats: 'jpeg, png, pdf' }"
    [dropzoneLabel]="'signup.drop-here' | translate"
    [dropzoneActionLabel]="'signup.click-to-select-file' | translate"
    [fakeUploadDuration]="registrationUpload.fakeUploadDuration"
    [mimeTypes]="registrationUpload.mimeTypes"
    [givenFile]="registrationUpload.givenFile"
    [response]="registrationUpload.response"
    (fileSelected)="documentSelected($event, 'businessRegistrationDocument', registrationUpload)"
    (fileDeleted)="documentDeleted(registrationUpload)">
  </enzo-file-upload>

  <section *ngIf="hasUploadedIdDocument() === false">
    <div class="text-and-icon-wrapper">
      <enzo-text kind="body-bold" balance="false" class="txt-related-to-icon">{{ 'signup.id-section-title' | translate }}</enzo-text>
      <!-- tooltip icon - to include in V.O2 -->
      <!-- <enzo-icon icon="info-outline" color="purple" size="medium"></enzo-icon> -->
    </div>

    <enzo-text kind="emphasis-label" color="grey-ultradark" balance="false" class="select-id-type">{{ 'signup.select-id-type' | translate }}</enzo-text>

    <enzo-radio-button-group [layout]="radioBtnLayout" [(ngModel)]="selectedIdType" (ngModelChange)="onIdTypeChange($event)">
      <enzo-radio-button value="1" [label]="'signup.id-card' | translate"></enzo-radio-button>
      <enzo-radio-button value="2" [label]="'signup.passport' | translate"></enzo-radio-button>
      <enzo-radio-button value="3" [label]="'signup.driving-license' | translate"></enzo-radio-button>
    </enzo-radio-button-group>

    <div class="id-upload-wrapper">
      <enzo-file-upload
        [label]="firstIdDocumentLabel | translate"
        [description]="'self-registration.buyer.allowed-formats' | translate: { allowedFormats: 'jpeg, png, pdf' }"
        [dropzoneLabel]="'signup.drop-here' | translate"
        [dropzoneActionLabel]="'signup.click-to-select-file' | translate"
        [fakeUploadDuration]="registrationUpload.fakeUploadDuration"
        [mimeTypes]="idFrontUpload.mimeTypes"
        [givenFile]="idFrontUpload.givenFile"
        [response]="idFrontUpload.response"
        (fileSelected)="documentSelected($event, idDocumentFrontType, idFrontUpload)"
        (fileDeleted)="documentDeleted(idFrontUpload)">
      </enzo-file-upload>

      <enzo-file-upload
        *ngIf="secondIdDocumentVisible"
        [label]="'signup.back-view' | translate"
        [description]="'self-registration.buyer.allowed-formats' | translate: { allowedFormats: 'jpeg, png, pdf' }"
        [dropzoneLabel]="'signup.drop-here' | translate"
        [dropzoneActionLabel]="'signup.click-to-select-file' | translate"
        [fakeUploadDuration]="registrationUpload.fakeUploadDuration"
        [mimeTypes]="idBackUpload.mimeTypes"
        [givenFile]="idBackUpload.givenFile"
        [response]="idBackUpload.response"
        (fileSelected)="documentSelected($event, 'idDocumentBack', idBackUpload)"
        (fileDeleted)="documentDeleted(idBackUpload)">
      </enzo-file-upload>
    </div>
  </section>
</mat-dialog-content>

<div mat-dialog-actions class="actions">
  <enzo-button [label]="'general.cancel' | translate" kind="secondary" (enzoClick)="closeModal()" [disabled]="saveInProgress || disableButtons"></enzo-button>
  <enzo-button
    [label]="'general.save' | translate"
    kind="primary"
    (enzoClick)="saveAccountData()"
    [loading]="saveInProgress"
    [disabled]="saveInProgress || disableButtons"></enzo-button>
</div>
