@if (bidsCount()) {
  <div class="root" (click)="togglePanelOnPaddingAreaClick($event)" testid="root-el">
    <app-expandable-panel [(expandedState)]="isExpanded" (expandedStateChange)="expandedStateChange($event)" testid="expandable-panel">
      <ng-container panelTitle>
        <enzo-icon icon="bid" size="small" />
        {{ 'auction.already-x-bids' | translate: { numBids: bidsCount() } }}
      </ng-container>
      <app-bidders-list panelContent [bids]="bids()" [loading]="bidsLoading()" testid="bidders-list" />
    </app-expandable-panel>
  </div>
}
