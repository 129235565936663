<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngFor="let tab of vm.tabs; index as index; trackBy: trackByLocation">
    <enzo-button
      [kind]="vm.selectedTabIndex === index ? 'primary' : 'secondary'"
      variant="normal"
      [label]="tab.parts | slice: 0 : 3 | i18nArray: i18nVehiclePartPipe"
      (enzoClick)="handleIndexChange(index, vm.tabs)" />
  </ng-container>
  <app-image-gallery [galleryImages]="vm.tabs[vm.selectedTabIndex]?.images || []" />
</ng-container>
