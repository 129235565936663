<div class="grid header">
  <enzo-text kind="emphasis-label" color="grey-dark" testid="bidder-header">
    {{ 'auction.bidder' | translate }}
  </enzo-text>
  <enzo-text kind="emphasis-label" color="grey-dark" testid="bid-value-header">
    {{ 'auction.bid-value' | translate }}
  </enzo-text>
  <enzo-text kind="emphasis-label" color="grey-dark" testid="bid-time-header">
    {{ 'auction.bid-time' | translate }}
  </enzo-text>
</div>
@if (!loading()) {
  @for (bidsGroup of bids(); let i = $index; track i) {
    <div class="grid bids-group" testid="bids-group">
      @for (bid of bidsGroup; let j = $index; track j) {
        <enzo-text [kind]="i === 0 && j === 0 ? 'emphasis-label' : 'label'" color="black" align="right">
          {{ bid.buyerName }}
        </enzo-text>
        <enzo-text [kind]="i === 0 && j === 0 ? 'emphasis-label' : 'label'" color="black" align="right">
          {{ bid.value | currencyEuro }}
        </enzo-text>
        <enzo-text [kind]="i === 0 && j === 0 ? 'emphasis-label' : 'label'" color="black" align="right">
          {{ bid.placedAt | date: 'dd.MM.yy (HH:mm)' }}
          @if (bid.triggeringBuyerName) {
            <enzo-text [kind]="i === 0 && j === 0 ? 'emphasis-label' : 'label'" color="grey" align="right">
              [{{ 'auction.bid-triggered-text' | translate }} {{ bid.triggeringBuyerName }}]
            </enzo-text>
          }
        </enzo-text>
      }
    </div>
  }
} @else {
  <div class="grid" testid="loaders">
    <ngx-skeleton-loader />
    <ngx-skeleton-loader />
    <ngx-skeleton-loader />
  </div>
}
