<ng-container *ngIf="!(auction | isInstantPurchaseActive); else instantPurchaseFooter">
  <div class="container">
    <div class="bid-info">
      <ng-container [ngTemplateOutlet]="statusInfo"></ng-container>
      <app-price-target-info [auction]="auction" testid="price-target-info"></app-price-target-info>
      <div>
        <app-highest-bid-value [auction]="auction" testid="highest-bid-value"></app-highest-bid-value>
        <ng-container [ngTemplateOutlet]="auctionVat"></ng-container>
      </div>
    </div>
    <div class="actions-container">
      <ng-container [ngTemplateOutlet]="statusInfo"></ng-container>
      <div class="actions">
        <enzo-button (enzoClick)="placeBid()" [label]="'action.do-bid' | translate" testid="place-bid-button" stretch></enzo-button>
        <enzo-button
          (enzoClick)="instantlyAddToBid(minimalBidIncrement)"
          [label]="'+' + (minimalBidIncrement | currencyEuro)"
          kind="secondary"
          variant="highlight"
          testid="instantly-add-to-bid-button"
          stretch></enzo-button>
        <app-watchlist-button [bright]="true" [auction]="auction" watchlistLocation="Detail view footer" />
        <app-notes-button bright popoverOnTop [auctionUuid]="auction.uuid" />
      </div>
    </div>
  </div>
</ng-container>

<ng-template #instantPurchaseFooter>
  <div class="container">
    <div class="instant-purchase">
      <ng-container [ngTemplateOutlet]="statusInfo"></ng-container>
      <div class="actions">
        <enzo-button
          variant="highlight"
          [label]="'action.buy-now-for' | translate: { price: auction.instantPurchasePrice | currency: 'EUR' }"
          (enzoClick)="makeInstantPurchase()"
          testid="instant-purchase-button"
          stretch>
        </enzo-button>
        <ng-container [ngTemplateOutlet]="auctionVat"></ng-container>
        <app-watchlist-button [bright]="true" [auction]="auction" watchlistLocation="Detail view footer" />
        <app-notes-button bright popoverOnTop [auctionUuid]="auction.uuid" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #statusInfo>
  <app-status-info [auctionBuyer]="auction" progressBar="hot-bid-bottom" testid="status-info"></app-status-info>
</ng-template>

<ng-template #auctionVat>
  <app-auction-vat [auction]="auction" testid="auction-vat"></app-auction-vat>
</ng-template>
