// Angular modules
import { CurrencyPipe, IMAGE_LOADER } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Environment variables
import { environment } from '@cosCoreEnvironments/environment';
import { AppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';

// Vendor modules
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClock as farClock, faFileAlt as farFileAlt } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

// Translation & locales
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { COS_CONSTANTS, CosI18nService, CountryCodeService } from '@caronsale/frontend-services';
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { CosLocales } from '@caronsale/frontend-utils';

// App services
import { CosSellerClientService } from '@cosCoreServices/cos-seller-client/cos-seller-client.service';
import { CosBuyerClientService } from './services/cos-salesman-client/cos-buyer-client.service';
import { CosPublicClientService } from '@cosCoreServices/cos-public-client/cos-public-client.service';
import { GlobalErrorHandlerService } from '@cosCoreServices/global-error-handler/global-error-handler.service';
import { I18nValidationService } from '@cosCoreServices/validation-service/i18n-validation.service';
import { I18nSnackService } from '@cosCoreServices/i18n-snack/i18n-snack.service';
import { ConfigService } from '@cosCoreServices/config/config.service';

// App modules
import { AppComponent } from './app.component';
import { AppMaterialModule } from './modules/app-material.module';
import { AppCoreRoutingModule } from './modules/app-routing.module';
import { ImprintModule } from '@cosCoreComponents/general/imprint/imprint.module';

import { NotificationCenterModule } from '@cosCoreFeatures/common/notification-center/notification-center.module';
import { PickupConfirmationModule } from '@cosCoreFeatures/pickup-confirmation/pickup-confirmation.module';
import { BiddingConfirmationDialogComponent } from '@cosBuyer/partials/services/bidding/bidding-confirmation-dialog/bidding-confirmation-dialog.component';
import { CosBottomOverlayModule } from '@cosCoreComponents/cos-common/cos-bottom-overlay/cos-bottom-overlay.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import {
  MAT_LEGACY_CHECKBOX_DEFAULT_OPTIONS as MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatLegacyCheckboxDefaultOptions as MatCheckboxDefaultOptions,
} from '@angular/material/legacy-checkbox';
import { GeneralBusinessTermsModalComponent } from '@cosCoreComponents/general/general-business-terms-modal/general-business-terms-modal.component';
import { MobileBannerOverlayModule } from '@cosCoreFeatures/buyer/components/mobile-banner-overlay/mobile-banner-overlay.module';
import { EnzoModalMessageComponent } from '@cosCoreComponents/modal-dialogs/enzo-modal-message.component';
import { CompanyContactModalComponent } from '@cosCoreComponents/general/company-contact-modal/company-contact-modal.component';
import { customCloudinaryLoader } from '@cosCoreUtils/cloudinary-loader';
import { BuyerSelfRegisterModalComponent } from '@cosBuyer/partials/buyer-self-register-modal/buyer-self-register-modal.component';
import { GeneralIncompatibleBrowserModalComponent } from '@cos/components/incompatible-browser-modal/general-incompatible-browser-modal.component';
import { I18nErrorDialogComponent } from '@cos/components/general/i18n/error-dialog/i18n-error-dialog.component';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { SentryGlobalErrorHandler } from '@cosCoreServices/global-error-handler/sentry-global-error-handler.service';
import { PhraseTranslateLoader } from '@cosCoreUtils/translate/phrase-translate-loader';
import { CosMissingTranslationHandler } from '@cosCoreUtils/translate/cos-missing-translations-handler';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';
import { BuyerAuctionDetailsModalModule } from '@cos/components/buyer/buyer-auction-details-modal/buyer-auction-details-modal.module';

CosLocales.registerLocales();

const appInitializerFn = (appConfig: ConfigService) => () => {
  return appConfig.loadAppConfig();
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular and 3rd party
    AngularEditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    BuyerAuctionDetailsModalModule,
    BuyerSelfRegisterModalComponent,
    ColorPickerModule,
    CompanyContactModalComponent,
    FormsModule,
    FlexModule,
    FontAwesomeModule,
    HttpClientModule,
    NgxChartsModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    ToastrModule.forRoot(),

    // our own
    AppMaterialModule,
    AppCoreRoutingModule,
    BiddingConfirmationDialogComponent, // contains dialogs opened by bidding-service functions
    CosBottomOverlayModule,
    EnzoModalMessageComponent,
    GeneralBusinessTermsModalComponent,
    GeneralIncompatibleBrowserModalComponent,
    I18nErrorDialogComponent,
    ImprintModule,
    MobileBannerOverlayModule,
    NotificationCenterModule,
    PickupConfirmationModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new PhraseTranslateLoader(http, environment.phraseConfig, environment.translationUrls),
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CosMissingTranslationHandler,
      },
    }),
    environment.envModules,
    EnzoComponentsModule,
  ],
  providers: [
    COS_CONSTANTS,
    CountryCodeService,
    // ToDo: Update dependency injections to use I18Service directly and delete this "alias" (I8nService is provided in root)
    {
      provide: CosI18nService,
      useClass: I18nService,
    },
    {
      provide: AppEnvironment,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigService],
    },
    CosPublicClientService,
    CosSellerClientService,
    CosBuyerClientService,
    CurrencyPipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    HttpClient,
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => {
        return translateService.currentLang?.slice(0, 2) || 'de';
      },
    },
    I18nValidationService,
    I18nSnackService,
    {
      provide: 'googleTagManagerResourcePath',
      useValue: 'https://www.googletagmanager.com/gtag/js',
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.googleTagManagerId,
    },
    {
      provide: 'googleAnalyticsId',
      useValue: environment.googleAnalyticsId,
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: 'primary' } as MatCheckboxDefaultOptions,
    },
    { provide: IMAGE_LOADER, useValue: customCloudinaryLoader },
    {
      provide: ErrorHandler,
      useFactory: (cosCoreClient, productAnalyticsService) => new SentryGlobalErrorHandler(cosCoreClient, productAnalyticsService),
      deps: [CosCoreClient, ProductAnalyticsService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(
    library: FaIconLibrary,
    matIconRegistry: MatIconRegistry,
    /* eslint-disable @typescript-eslint/no-unused-vars */
    productAnalyticsService: ProductAnalyticsService, // must be injected to start tracking sessions as soon as possible
  ) {
    library.addIconPacks(fas);
    library.addIcons(farFileAlt, farClock);
    matIconRegistry.setDefaultFontSetClass('fas');
  }
}
