import { Component, Input, OnChanges, Optional } from '@angular/core';

import { IBuyerAuctionView, IBuyerUser, IPrebookedService } from '@caronsale/cos-models';

import { BuyerAuctionService } from '@cosCoreFeatures/auction-detail/common/auction-service/buyer-auction.service';
import { BiddingService } from '@cosBuyer/partials/services/bidding/bidding.service';
import { EnzoValidation } from '@caronsale/enzo-angular';
import { BehaviorSubject, debounceTime, filter, map, Observable, Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RecommendationIdService } from '@cosBuyer/partials/services/recommendation-id/recommendation-id.service';
interface IVm {
  auction: IBuyerAuctionView;
  canSetBiddingAgent: boolean;
  isBiddingAgentSet: boolean;
  minimalBidThatIsHigher: number;
  maxBidValue: number;
  bidStep: number;
  bidIncrements: number[];
}

@Component({
  selector: 'app-bidding-actions',
  templateUrl: './bidding-actions.component.html',
  styleUrls: ['./bidding-actions.component.scss'],
})
export class BiddingActionsComponent implements OnChanges {
  @Input()
  public buyerUser: IBuyerUser;

  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public currentPrebookedServices: IPrebookedService[];

  public animateErrorState = false;

  private auctionSubject: BehaviorSubject<IBuyerAuctionView> = new BehaviorSubject<IBuyerAuctionView>(null);
  private bidValueChangedSubject: Subject<number> = new Subject<number>();

  private toNumber(val: string | number): number | null {
    if (typeof val === 'number') {
      return val;
    }
    if (typeof val !== 'string' || val === '') {
      return null;
    }
    const numberValue = Number(val);
    return isNaN(numberValue) ? null : numberValue;
  }

  public validateBidHighEnough: ValidatorFn = (bidValueControl: AbstractControl): ValidationErrors | null => {
    const numberValue = this.toNumber(bidValueControl.value);
    if (this.auction && numberValue !== null && !this.biddingService.isBidHighEnough(this.auction, numberValue)) {
      return {
        bidNotHighEnough: {
          type: 'incomplete',
          messageKey: 'auction.minimum-bid',
          translationParams: {
            minimumPrice: this.currencyPipe.transform(this.biddingService.getMinimalBidThatIsHigher(this.auction), 'EUR', 'symbol', '1.0-0'),
          },
        },
      };
    }
    return null;
  };

  public validateBidNotTooHigh: ValidatorFn = (bidValueControl: AbstractControl): ValidationErrors | null => {
    const numberValue = this.toNumber(bidValueControl.value);
    if (this.auction && numberValue !== null && this.biddingService.isBidTooHigh(this.auction, numberValue)) {
      this.bidValueChangedSubject.next(numberValue);
      return {
        bidTooHigh: {
          type: 'incomplete',
          messageKey: 'auction.maximum-bid',
        },
      };
    }
    return null;
  };

  public bidAmount: FormControl<number> = new FormControl<number>(null, [
    this.validateBidHighEnough,
    this.validateBidNotTooHigh,
    EnzoValidation.wrapValidator(Validators.required, 'error.required'),
  ]);

  public vm$: Observable<IVm> = this.auctionSubject.pipe(
    map(auction => ({
      auction,
      canSetBiddingAgent: !this.buyerAuctionService.isHotBidPhaseActive(auction),
      isBiddingAgentSet: this.biddingService.isBiddingAgentSet(auction),
      minimalBidThatIsHigher: this.biddingService.getMinimalBidThatIsHigher(auction),
      // IMPORTANT: We need to make sure undefined is the default value set on the enzo-textfield max otherwise enzo throws and the bid can't be submitted
      maxBidValue: this.biddingService.getHighestPossibleBid(auction) || undefined,
      bidStep: this.biddingService.getMinimalBidIncrement(auction),
      bidIncrements: this.biddingService.getBidIncrements(auction),
    })),
  );

  public constructor(
    private biddingService: BiddingService,
    private buyerAuctionService: BuyerAuctionService,
    private currencyPipe: CurrencyPipe,
    private googleAnalyticsService: GoogleAnalyticsService,
    private productAnalyticsService: ProductAnalyticsService,
    @Optional() private recommendationIdService?: RecommendationIdService,
  ) {
    this.bidValueChangedSubject
      .pipe(debounceTime(300), takeUntilDestroyed())
      .subscribe(value => this.biddingService.trackIfBidTooHigh(this.auction, value, 'Details view'));
  }

  public ngOnChanges(): void {
    this.auctionSubject.next(this.auction);
  }

  private isBidAmountInvalid(): boolean {
    if (this.bidAmount.touched && this.bidAmount.invalid) {
      this.animateErrorState = true;
      return true;
    }
    this.bidAmount.markAsTouched();
    this.bidAmount.updateValueAndValidity();
    return this.bidAmount.invalid;
  }

  public bidOnAuction(): void {
    if (this.isBidAmountInvalid()) {
      return;
    }
    this.googleAnalyticsService.trackBidByValueFromDetailViewClick();
    this.biddingService
      .directlyBidOnAuction(
        this.buyerUser,
        this.auction,
        this.currentPrebookedServices,
        this.bidAmount.value,
        undefined,
        this.recommendationIdService?.getRecommendationId(),
      )
      .pipe(filter(Boolean))
      .subscribe(biddingResult => {
        this.auctionSubject.next({
          ...this.auction,
          currentHighestBidValue: this.bidAmount.value,
          amIHighestBidder: true,
        });

        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bid by value',
          'Bid value': this.bidAmount.value,
          'Bid source': 'Details view',
          insert_id: biddingResult.uuid,
        });

        this.bidAmount.reset();
      });
  }

  public setBiddingAgent(): void {
    if (this.isBidAmountInvalid()) {
      return;
    }
    this.googleAnalyticsService.trackBiddingAgentFromDetailViewClick();
    this.biddingService
      .setBiddingAgent(this.buyerUser, this.auction, this.currentPrebookedServices, this.bidAmount.value)
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.auctionSubject.next({
          ...this.auction,
          amIHighestBidder: true,
        });

        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bidding agent',
          'Bid value': this.bidAmount.value,
          'Bid source': 'Details view',
        });

        this.bidAmount.reset();
      });
  }

  public removeBiddingAgent(): void {
    this.googleAnalyticsService.trackRemoveBiddingAgentFromDetailViewClick();
    this.biddingService.removeBiddingAgent(this.auction.uuid);
  }

  public async incrementBidValue(incrementAmount: number): Promise<void> {
    this.googleAnalyticsService.trackBidByIncrementFromDetailViewClick();
    this.biddingService
      .instantlyAddToBid(this.buyerUser, this.auction, this.currentPrebookedServices, incrementAmount)
      .pipe(filter(Boolean))
      .subscribe(biddingResult => {
        this.bidAmount.reset();
        this.productAnalyticsService.trackEvent('bidPlaced', {
          'Auction end time': new Date(this.auction.endingTime).toISOString(),
          'Auction uuid': this.auction.uuid,
          'Bid timestamp': new Date().toISOString(),
          'Bid type': 'Bid by increment',
          'Bid value': this.auction.currentHighestBidValue + incrementAmount,
          'Bid source': 'Details view',
          insert_id: biddingResult.uuid,
        });
      });
  }
}
