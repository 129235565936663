import { ChangeDetectionStrategy, Component, computed, ElementRef, Inject, input, OnDestroy, OnInit, signal } from '@angular/core';
import { IBuyerAuctionBidView, IBuyerAuctionView } from '@caronsale/cos-models';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subject, filter, takeUntil } from 'rxjs';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';
import { BiddersListComponent } from './bidders-list/bidders-list.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-bids-amount',
  templateUrl: './bids-amount.component.html',
  styleUrls: ['./bids-amount.component.scss'],
  imports: [TranslateModule, EnzoComponentsModule, ExpandablePanelModule, BiddersListComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class]': 'hostClass()',
  },
})
export class BidsAmountComponent implements OnDestroy, OnInit {
  public auction = input.required<IBuyerAuctionView>();

  public alignPanel = input<'right' | 'left'>('right');

  public hostClass = computed(() => (this.alignPanel() === 'left' ? 'align-left' : 'align-right'));
  public isExpanded = false;
  public bids = signal<IBuyerAuctionBidView[][]>(null);
  public bidsLoading = signal<boolean>(false);
  public bidsCount = computed(() => {
    const bidsFromPreviousAuctions = this.getBidsFromPreviousAuctions(this.bids(), this.auction().uuid);
    return (bidsFromPreviousAuctions.length || 0) + this.auction().numBids;
  });

  private removeClickHandler: Subject<void> = new Subject<void>();

  public constructor(
    //
    private elementRef: ElementRef,
    private cosBuyerClient: CosBuyerClientService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public ngOnInit(): void {
    this.fetchBids();
  }

  public ngOnDestroy() {
    this.removeWindowClickHandler();
  }

  public togglePanelOnPaddingAreaClick(event: Event) {
    if (event.currentTarget === event.target) {
      // click is directly on the div, not in the expandable panel child
      this.setPanelState(!this.isExpanded);
    }
  }

  public expandedStateChange(isExpanded: boolean) {
    if (isExpanded) {
      // fetch again every time it is opened
      this.fetchBids();
      // close the overlay if something was clicked outside of us (that bubbled up to 'window')
      fromEvent(this.document, 'click')
        .pipe(
          takeUntil(this.removeClickHandler),
          filter(event => !this.elementRef.nativeElement.firstElementChild.contains(event.target)),
        )
        .subscribe(() => this.setPanelState(false));
    } else {
      this.removeWindowClickHandler();
    }
  }

  private setPanelState(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    this.expandedStateChange(isExpanded);
  }

  private fetchBids(): void {
    if (!this.bids()?.length) {
      this.bidsLoading.set(true);
    }

    this.cosBuyerClient.getBiddersList(this.auction()).subscribe((bids: IBuyerAuctionBidView[]) => {
      this.bids.set(this.splitBidsByAuctionUuid(bids));
      this.bidsLoading.set(false);
    });
  }

  private removeWindowClickHandler(): void {
    this.removeClickHandler.next();
  }

  private splitBidsByAuctionUuid(bids: IBuyerAuctionBidView[]): IBuyerAuctionBidView[][] {
    const auctionUuids = Array.from(new Set(bids.map(bid => bid.auctionUUID)));
    return auctionUuids.map(uuid => bids.filter(bid => bid.auctionUUID === uuid));
  }

  private getBidsFromPreviousAuctions(bids: IBuyerAuctionBidView[][], currentAuctionUuid: string): IBuyerAuctionBidView[] {
    return bids?.map(bids => bids.filter(bid => bid.auctionUUID !== currentAuctionUuid)).flat() || [];
  }
}
