// Angular
import { Component, HostBinding, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivationEnd, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { filter, map, Observable, take } from 'rxjs';

// CoS
import { CosCoreClient } from '@cosCoreServices/core-client/cos-core-client.service';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { I18nService } from '@cosCoreServices/i18n/i18n.service';
import { environment } from '@cosCoreEnvironments/environment';
import { IAuthenticationResult, IServiceMetaData } from '@caronsale/cos-models';
import { GeneralIncompatibleBrowserModalComponent } from '@cosCoreComponents/incompatible-browser-modal/general-incompatible-browser-modal.component';
import { BrowserDetectionService } from '@cosCoreServices/browser-detection/browser-detection.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';
import { I18nErrorDialogComponent } from '@cosCoreComponentsGeneral/i18n/error-dialog/i18n-error-dialog.component';
import { I18nInfoDialogComponent } from '@cosCoreComponentsGeneral/i18n/info-dialog/i18n-info-dialog.component';

import { defineCustomElements } from '@caronsale/enzo/loader';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { CosSellerClientService } from '@cosCoreServices/cos-seller-client/cos-seller-client.service';

defineCustomElements();

export const HIDE_MOBILE_FULLSCREEN_OVERLAY = 'hideMobileFullscreenOverlay';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // Force refresh after 6 hours
  private readonly FORCE_REFRESH_THRESHOLD_IN_MS: number = 6 * 3600 * 1000;

  public uiEnvironmentLabel: string;
  public serviceEnvironmentLabel: string;
  public bannerIsHidden: boolean;
  public hideMobileFullscreenOverlay: boolean;

  @HostBinding('@.disabled')
  public animationsDisabled = Boolean(window['Cypress']);

  public constructor(
    public dialog: MatDialog,
    private router: Router,
    private cosCoreClient: CosCoreClient,
    private browserDetection: BrowserDetectionService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public i18nService: I18nService,
    private constants: COS_CONSTANTS,
    private cosBuyerClientService: CosBuyerClientService,
    private cosSellerClientService: CosSellerClientService,
  ) {
    this.uiEnvironmentLabel = environment.label;
    this.serviceEnvironmentLabel = '?';
    this.bannerIsHidden = false;
  }

  public ngOnInit(): void {
    // redirect to /pickup for pin[-staging|-dev].caronsale.de domains
    if (window.location.host.startsWith('pin')) {
      // @see https://github.com/angular/angular/issues/12157#issuecomment-799331302
      this.router.events
        .pipe(
          filter((event): event is RoutesRecognized => !!event && event instanceof RoutesRecognized),
          take(1),
          map(event => event.state.root.queryParamMap.get('id')),
        )
        .subscribe(id => this.router.navigate(['/pickup'], { queryParams: { id } }));
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.pageEmitter('section', event.url);
      }
      if (event instanceof NavigationStart) {
        this.hideMobileFullscreenOverlay = false;
      }
      if (event instanceof ActivationEnd && event.snapshot.data?.[HIDE_MOBILE_FULLSCREEN_OVERLAY]) {
        this.hideMobileFullscreenOverlay = true;
      }
    });

    if (this.browserDetection.isInternetExplorer()) {
      this.dialog.open(GeneralIncompatibleBrowserModalComponent, {
        disableClose: true,
      });
      return;
    }

    let isShowingErrorDialog = false;

    this.cosCoreClient.setUnauthorizedHandler(() => {
      if (!isShowingErrorDialog) {
        isShowingErrorDialog = true;
        this.cosCoreClient.signOff().subscribe(() => {
          this.cosBuyerClientService.reset();
          this.cosSellerClientService.resetData();
          this.router.navigate(['/login']);
        });
        I18nErrorDialogComponent.show(this.dialog, 'error.session-expired', '', '450px').subscribe(() => (isShowingErrorDialog = false));
      }
    });

    this.cosCoreClient.setSessionExpiredHandler(() => {
      // Go back to login page
      this.router.navigate(['/login']).then(() => {
        if (!isShowingErrorDialog) {
          isShowingErrorDialog = true;

          I18nErrorDialogComponent.show(this.dialog, 'error.session-expired', '', '450px').subscribe(() => {
            this.cosCoreClient.signOff();
            isShowingErrorDialog = false;
          });
        }
      });
    });

    this.cosCoreClient.setNotFoundHandler(() => {
      if (!isShowingErrorDialog) {
        isShowingErrorDialog = true;

        I18nErrorDialogComponent.show(this.dialog, 'error.not-available-try-again', '', '450px').subscribe(() => (isShowingErrorDialog = false));
      }
    });

    this.cosCoreClient.setServerErrorHandler(() => {
      if (!isShowingErrorDialog) {
        isShowingErrorDialog = true;

        I18nErrorDialogComponent.show(
          this.dialog,
          'error.server-problems-try-again-or-contact',
          '',
          { infoEmail: this.constants.INFO_EMAIL },
          '450px',
        ).subscribe(() => (isShowingErrorDialog = false));
      }
    });

    const serviceNotAvailable$ = new Observable<number>(observer => {
      let serviceNotAvailableCount = 0;
      this.cosCoreClient.setServiceNotAvailableHandler(() => {
        serviceNotAvailableCount++;
        setTimeout(() => {
          observer.next(serviceNotAvailableCount);
          serviceNotAvailableCount = 0;
        }, 10000);
      });
    });

    serviceNotAvailable$.subscribe(serviceNotAvailableCount => {
      if (!isShowingErrorDialog && serviceNotAvailableCount > 5) {
        isShowingErrorDialog = true;
        I18nErrorDialogComponent.show(this.dialog, 'dialog.general.connection-issue', '', '450px').subscribe(() => (isShowingErrorDialog = false));
      }
    });

    // TODO: Do we need this?
    this.cosCoreClient.setNoConnectionHandler(() => {
      if (!isShowingErrorDialog) {
        isShowingErrorDialog = true;

        I18nErrorDialogComponent.show(this.dialog, 'error.server-not-reachable', '', '450px').subscribe(() => (isShowingErrorDialog = false));
      }
    });

    // TODO: Do we really need this here?
    this.cosCoreClient.setBidProhibitedError(() => {
      if (!isShowingErrorDialog) {
        isShowingErrorDialog = true;

        I18nInfoDialogComponent.show(this.dialog, 'dialog.general.bidding-not-allowed').subscribe(() => {
          isShowingErrorDialog = false;
        });
      }
    });

    const checkIfRefreshIsNecessary = (now: number) => {
      const lastUpdate: number = Number(localStorage.getItem('lastPageRefresh'));

      if (lastUpdate) {
        // If last update has happened
        if (lastUpdate < now - this.FORCE_REFRESH_THRESHOLD_IN_MS) {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Refreshing....`);
          localStorage.setItem('lastPageRefresh', now.toString());
          if (window['SENTRY_CLIENT']) {
            window['SENTRY_CLIENT'].getOptions().enabled = false;
          }
          location.reload();
        } else {
          console.log(`Last refresh has happened ${(now - lastUpdate) / 1000} seconds ago. Therefore not refreshing.`);
        }
      } else {
        // Initial refresh
        localStorage.setItem('lastPageRefresh', now.toString());
      }
    };

    this.cosCoreClient.getServiceMetaInfo().subscribe((metaInfo: IServiceMetaData) => {
      this.serviceEnvironmentLabel = metaInfo.environment;
      checkIfRefreshIsNecessary(metaInfo.currentTimeInMilliseconds);
    });

    if (localStorage.getItem('persistedAuthentication')) {
      this.cosCoreClient.validateAuthentication().subscribe((response: IAuthenticationResult) => {
        if (response?.authenticated) {
          // Persist updated authentication
          this.cosCoreClient.updateAuthenticationData(response);
        } else {
          this.router.navigate(['/login']);
          this.cosCoreClient.signOff();
        }
      });
    }
  }

  public isServiceProductionEnvironment(): boolean {
    return this.serviceEnvironmentLabel === 'production' || this.serviceEnvironmentLabel === '?';
  }

  public isUIProductionEnvironment(): boolean {
    return environment.label === 'production';
  }
}
